import React from 'react'
import { Tab } from '@headlessui/react'
import autoprefixer from 'autoprefixer'

const Tabs = () => {

    const steps = [
        {
            id: "01",
            title: "Establish",
            description: "We start with the engagement meeting to establish the client-planner relationship.",
        },
        {
            id: "02",
            title: "Envision",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
            id: "03",
            title: "Execute",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
            id: "04",
            title: "Examine",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        },
    ]

    return (
        <div className='container mx-auto my-12'>
            <Tab.Group>
                <div className="grid grid-cols-3 gap-4">
                    <Tab.List className="grid gap-2">

                        {steps.map((step) => (
                            <Tab className="w-full rounded-md p-4 bg-white shadow-around text-left flex overflow-hidden hover:overflow-visible content-center items-center border-gray-50 hover:bg-gray-50 group">
                                {({ selected }) => (
                                    <>
                                        <div className={"h-24 w-24 -ml-8 mr-2 rounded-full flex-none inline-flex items-center justify-center group-hover:scale-105 " + (selected ? 'bg-blue-500' : 'bg-blue-100')}>
                                            <div className="flex flex-col items-center">
                                                <p className={"text-xs uppercase leading-none font-semibold " + (selected ? 'text-blue-100' : 'text-blue-500')}>step</p>
                                                <p className={"text-2xl leading-none font-black " + (selected ? 'text-blue-100' : 'text-blue-500')}>{step.id}</p>
                                            </div>
                                        </div>
                                        <div className="">
                                            <p className="text-gray-900 mb-1 text-lg">{step.title}</p>
                                            <p className='text-gray-600 text-sm'>{step.description}</p>
                                        </div>
                                    </>
                                )}
                            </Tab>
                        ))}


                        {/* <Tab className="w-full rounded-md p-4 bg-white shadow-around text-left flex overflow-hidden content-center items-center">
                            <div className="h-24 w-24 -ml-8 mr-2 bg-blue-100 rounded-full flex-none inline-flex items-center justify-center">
                                <div className="flex flex-col items-center">
                                    <p className="text-xs uppercase leading-none font-semibold text-blue-500">step</p>
                                    <p className="text-2xl leading-none font-black text-blue-500">01</p>
                                </div>
                            </div>
                            <div className="">
                                <p className="text-gray-900 mb-1 text-lg">Establish</p>
                                <p className='text-gray-600 text-sm'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                            </div>
                        </Tab>
                        <Tab className="w-full rounded-md p-4 bg-blue-500 shadow-around text-left flex overflow-hidden content-center"><div className="h-24 w-24 -ml-8 mr-2 mt-0 bg-blue-100 rounded-full flex-none"></div><div className=""> <p className="text-white mb-1">Step 2</p><p className='text-blue-50 text-sm'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p></div></Tab>
                        <Tab className="w-full rounded-md p-4 bg-blue-500 shadow-around text-left flex overflow-hidden content-center"><div className="h-24 w-24 -ml-8 mr-2 mt-0 bg-blue-100 rounded-full flex-none"></div><div className=""> <p className="text-white mb-1">Step 3</p><p className='text-blue-50 text-sm'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p></div></Tab>
                        <Tab className="w-full rounded-md p-4 bg-blue-500 shadow-around text-left flex overflow-hidden content-center"><div className="h-24 w-24 -ml-8 mr-2 mt-0 bg-blue-100 rounded-full flex-none"></div><div className=""> <p className="text-white mb-1">Step 4</p><p className='text-blue-50 text-sm'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p></div></Tab>
                    */}
                    </Tab.List>
                    <Tab.Panels className="col-span-2">
                        <Tab.Panel>Content 1</Tab.Panel>
                        <Tab.Panel>Content 2</Tab.Panel>
                        <Tab.Panel>Content 3</Tab.Panel>
                        <Tab.Panel>Content 4</Tab.Panel>
                    </Tab.Panels>
                </div>
            </Tab.Group>
        </div>
    )
}

export default Tabs