import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

const Header = (props) => {
    return (
        <section className="bg-blue-500">
            <div className="container mx-auto py-12">
                <h1 className='text-white'>Hello World</h1>
            </div>
        </section >
    )
}

export default Header
