import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const ContentBrowser = () => {

    // const data = useStaticQuery(graphql`
    // query myQuery {
    //     allStrapiPosts {
    //       edges {
    //         node {
    //           Author {
    //             firstName
    //             lastName
    //           }
    //           Title
    //           titlephoto {
    //             localFile {
    //               url
    //             }
    //           }
    //         }
    //       }
    //     }
    //     allStrapiCategories {
    //         edges {
    //           node {
    //             category
    //           }
    //         }
    //       }
    //   }   
    // `)

    return (
        <>
            {/* OLD TOP SECTION  */}
            {/* <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap w-full mb-20">
                        <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Resources</h1>
                            <div className="h-1 w-20 bg-blue-500 rounded"></div>
                        </div>
                        <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>
                    </div>
                    <div className="flex flex-wrap w-full mb-20">
                        <div>
                            <h3 className="tracking-widest text-blue-500 text-xs font-medium title-font uppercase">Type</h3>
                            <select name="type" id="type" className="border-blue-500 border-2 p-4 w-80 mr-4">
                                <option value="" selected>All Types</option>
                                {data.allStrapiCategories.edges.map((category) => (
                                    <option value={category.node.category}>{category.node.category}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <h3 className="tracking-widest text-blue-500 text-xs font-medium title-font uppercase">Category</h3>
                            <select name="category" id="category" className="border-blue-500 border-2 p-4 w-80 mr-4">
                                <option value="" selected>All Categories</option>
                                {data.allStrapiCategories.edges.map((category) => (
                                    <option value={category.node.category}>{category.node.category}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <h3 className="tracking-widest text-blue-500 text-xs font-medium title-font uppercase">Author</h3>
                            <select name="author" id="author" className="border-blue-500 border-2 p-4 w-80 mr-4">
                                <option value="" selected>All Authors</option>
                                {data.allStrapiPosts.edges.map((author) => (
                                    <option value={author.node.Author.firstName}>{author.node.Author.firstName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* Blocks 2 */}
            {/* <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="grid gap-4 grid-cols-3">

                        {data.allStrapiPosts.edges.map((post) => (
                            <div className="bg-white py-24">{post.node.Title}</div>
                        ))}
                    </div >
                </div >
            </section > */}

        </>
    )
}

export default ContentBrowser
