import React from 'react'
import { Link } from 'gatsby'
import Button from '../elements/Button'
import { EnvelopeIcon, CalendarIcon } from '@heroicons/react/24/outline'

import people from '../../data/people.js'


const Team = () => {
    return (
        <section className="">
            {people.map((person) => (
                <section id={`${person.fname} ${person.lname}`} key={person.lname} className={"text-gray-600 body-font bg-gray-50 " + (person.imageLeft ? true : 'bg-blue-50')}>
                    <div className={"container mx-auto flex px-5 py-10 lg:pb-0 lg:flex-row flex-col items-center " + (person.imageLeft ? true : "lg:flex-row-reverse")}>
                        <div className="lg:max-w-lg lg:w-full w-1/2 mb-10 lg:mb-0 border-b-4 border-gray-200 lg:border-none">
                            <img className="object-cover object-center" alt={person.fname} src={person.image} />
                        </div>
                        <div className="lg:flex-grow lg:w-1/2 lg:pl-24 flex flex-col lg:items-start lg:text-left items-center text-center">
                            <small className="text-blue-500">{person.position}</small>
                            <div className="lg:flex mb-4 lg:items-end">
                                <h1 className="title-font sm:text-5xl text-3xl font-medium text-gray-600">{person.fname} {person.lname} </h1>
                                <div className="flex justify-center lg:justify-start">
                                    {person.certifications.map((cert, index) => (
                                        <p key={index} className='ml-2 text-gray-500'>
                                            {cert === 'CFP' ? <>{cert}<sup>®</sup></> : cert}
                                            {index < person.certifications.length - 1 && ','}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <p className="mb-8 leading-relaxed text-left">{person.bio}</p>
                            <div className="flex flex-wrap md:justify-center lg:justify-start mt-0 space-y-0 lg:space-y-3 xl:space-y-0">
                                {person.email &&
                                    <a className="px-6 py-3 text-lg leading-none rounded mr-4 inline-flex items-center hover:scale-105 active:scale-100 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-400 drop-shadow hover:text-white" href={"mailto:" + person.email}><EnvelopeIcon className='h-4 w-4 mr-2' /> Email {person.fname}</a>
                                }
                                {person.scheduler &&
                                    <a className="px-6 py-3 text-lg leading-none rounded mr-4 inline-flex items-center hover:scale-105 active:scale-100 text-blue-500 bg-gray-50 hover:bg-gray-100 active:bg-white drop-shadow hover:text-blue-500" target='_blank' href={person.scheduler}><CalendarIcon className='h-4 w-4 mr-2' /> Meet with {person.fname}</a>
                                }
                                {/* <div className={` ${person.author ? false : 'hidden'}`}>
                                    <Button path={"/posts/" + person.fname + "-" + person.lname} style="link">Read {person.fname}'s Posts</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            ))}

        </section>
    )

}

export default Team
