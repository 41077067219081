import React from 'react';
import Layout from '../components/layout'
import Cta from '../components/sections/Cta';
import Features2 from '../components/sections/Features2';
import ImageLeft from '../components/sections/ImageLeft'
import Accolades from '../components/sections/Accolades'
import ContentBrowser from '../components/sections/ContentBrowser'
import Features from '../components/sections/Features'
import Header from '../components/sections/Header';
import MiniContent from '../components/sections/MiniContent'
import NewHeader from '../components/sections/NewHeader';
import Services from '../components/sections/Services'
import SplitImage2 from '../components/sections/SplitImage2';
import SplitImage from '../components/sections/SplitImage'
import Team from '../components/sections/Team'
import MyModal from '../components/sections/Modal';
import Tabs2 from '../components/sections/Tabs2';
import Tabs from '../components/sections/Tabs';

const all = () => {
    return <Layout>
        Modal
        <MyModal />
        Header
        <Header />
        Accolades
        <Accolades />
        Content Browser
        <ContentBrowser />
        Cta
        <Cta />
        Features
        <Features />
        Features2
        <Features2 />
        ImageLeft
        <ImageLeft />
        Mini Content
        <MiniContent />
        New Header
        <NewHeader />
        Services
        <Services />
        SplitImage
        <SplitImage />
        Split Image 2
        <SplitImage2 />
        Team
        <Team />
        Tabs 1
        <Tabs />
        Tabs2
        <Tabs2 />

        {/* <div className="flex space-x-2 my-12 overscroll-x-contain overflow-auto hide-scrollbar">

                    {cards.map((card) => (

                        <div className='py-48 px-32 border border-black'>
                            {card.title}
                        </div>

                    ))}
                </div>

                <button className="prev"><ArrowLeftIcon className='h-8 w-8' /></button>
                <button className="next"><ArrowRightIcon className='h-8 w-8' /></button> */}

    </Layout>;
};

export default all;
