import React from 'react'
import rose from '../../images/logos/rose.png'
import image from '../../images/business2.png'

const Features = () => {
    return (

        <section id="values" className="text-gray-100 body-font" >
            <div className="bg-cover py-12" style={{ backgroundImage: `url(${image})`, }}>
                <div className="container mx-auto px-5 py-12">

                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gold-400">Our Services</h1>
                    <p className="mb-8 leading-relaxed text-blueGray-100 font-light"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas repellendus quibusdam alias assumenda esse iusto beatae doloremque rerum optio voluptatum. Voluptate doloribus veniam quidem? Facilis saepe libero laborum voluptatum atque!</p>
                    <div className="grid lg:grid-cols-3 gap-4">
                        <div className="">
                            <h2 className="title-font mb-4 font-medium text-gold-400">Financial Planning</h2>
                            <p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p>
                        </div>
                        <div className="">
                            <h2 className="title-font mb-4 font-medium text-gold-400">Investment Management</h2>
                            <p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p>
                        </div>
                        <div className="">
                            <h2 className="title-font mb-4 font-medium text-gold-400">Insurance</h2>
                            <p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p>
                        </div>
                        <div className="">
                            <h2 className="title-font mb-4 font-medium text-gold-400">Charitable Planning</h2>
                            <p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p>
                        </div>
                        <div className="">
                            <h2 className="title-font mb-4 font-medium text-gold-400">Estate Planning</h2>
                            <p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p>
                        </div>
                        <div className="">
                            <h2 className="title-font mb-4 font-medium text-gold-400">Business Services</h2>
                            <p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Features
