import React from 'react'

import { BriefcaseIcon, GiftIcon, KeyIcon, BoltIcon, } from '@heroicons/react/24/outline'
import Button from '../elements/Button'

const services = [
    // {
    //     title: "Financial Planning",
    //     description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit, neque distinctio pariatur rerum in ullam voluptate.",
    //     icon: ChartPieIcon,
    // },
    // {
    //     title: "Investment Management",
    //     description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit, neque distinctio pariatur rerum in ullam voluptate.",
    //     icon: DatabaseIcon,
    // },
    {
        title: "Insurance",
        description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit, neque distinctio pariatur rerum in ullam voluptate.",
        icon: BoltIcon,
    },
    {
        title: "Charitable Planning",
        description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit, neque distinctio pariatur rerum in ullam voluptate.",
        icon: GiftIcon,
    },
    {
        title: "Estate Planning",
        description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit, neque distinctio pariatur rerum in ullam voluptate.",
        icon: KeyIcon,
    },
    {
        title: "Business Services",
        description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit, neque distinctio pariatur rerum in ullam voluptate.",
        icon: BriefcaseIcon,
        path: '/services/business'
    },
]

const Services = () => {

    return (
        <section class="text-gray-600 body-font">
            <div class="container px-5 py-12 mx-auto flex flex-wrap">
                <div class="flex flex-wrap -m-4">
                    {services.map((service) => (
                        <div class="p-4 lg:w-1/2 md:w-full group">
                            <div class="flex rounded-lg p-4 sm:flex-row flex-col">
                                <div class="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 flex-shrink-0 group-hover:scale-110">
                                    <service.icon className='h-8 w-8 text-blue-500' />
                                </div>
                                <div class="flex-grow">
                                    <h3 className='mb-3'>{service.title}</h3>
                                    <p class="leading-relaxed text-base">{service.description}</p>
                                    <div className={`${service.path == null ? 'hidden' : 'flex'}`}>
                                        <Button path={service.path} style="link" size="mdlink">Learn More</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Services
