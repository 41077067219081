import React from 'react';
import image from '../../images/family.png'


const Features2 = () => {
    return <section id="values" className="text-gray-100 body-font" >
        <div className="bg-cover py-12" style={{ backgroundImage: `url(${image})`, }}>
            <div className="bg-blue-500/70">
                <div className="container mx-auto px-5 py-12">
                    <div className="grid lg:grid-cols-3 gap-4">
                        <div className="col-span-2">
                            <h1 id="why" className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gold-400">Why Clients Trust Us</h1>
                            <p className="mb-8 leading-relaxed text-blueGray-100 font-light"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas repellendus quibusdam alias assumenda esse iusto beatae doloremque rerum optio voluptatum. Voluptate doloribus veniam quidem? Facilis saepe libero laborum voluptatum atque!</p>
                            <div className="grid lg:grid-cols-2 gap-4">
                                <div className=""><div className="inline-flex"><h2 className="title-font mb-4 font-medium text-gold-400">Heading</h2></div><p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p></div>
                                <div className=""><h2 className="title-font mb-4 font-medium text-gold-400">Independence</h2><p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p></div>
                                <div className=""><h2 className="title-font mb-4 font-medium text-gold-400">Advisor Network</h2><p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p></div>
                                <div className=""><h2 className="title-font mb-4 font-medium text-gold-400">Team Based</h2><p className="mb-8 leading-relaxed text-blueGray-100 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum corporis debitis eaque vitae enim quos natus nemo laudantium quidem consequuntur.</p></div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="border-b-2 border-blue-300 flex flex-wrap justify-center content-center"><h1 className="font-black text-4xl text-white">Independent Fiduciary</h1></div>
                            <div className="border-b-2 border-blue-300 flex flex-wrap justify-center content-center"><h1 className="font-black text-4xl text-white">Advisor Network</h1></div>
                            <div className="flex flex-wrap justify-center content-center"><h1 className="font-black text-4xl text-white">Relationships</h1></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section >;
};

export default Features2;
