import React from 'react'
import { Link } from 'gatsby'
import Button from '../elements/Button'
import image from '../../images/family.png'
import { StaticImage } from 'gatsby-plugin-image'


const Cta = ({ text = "Roseman Wealth Advisors is a team-based comprehensive financial advisory company located in Tyler, Texas, whose professionals work with clients across the country." }) => {
    return (
        <section class="my-12 ">
            <div className="bg-cover rounded-2xl mx-auto container px-4">
                <div className="z-2 bg-blue-500 bg-opacity-90 rounded-2xl shadow-xl flex overflow-hidden relative">
                    <div className="lg:w-1/2 pl-8 pr-8 md:pr-0 md:pl-20 py-20 z-10">
                        <p className="mb-8 text-white text-xl">
                            {text}
                        </p>
                        <Button path="/what-to-expect" style="white">What to Expect</Button>
                        <Button path="/what-to-expect#contact" style="link-white">Get In Touch</Button>
                    </div>
                    {/* <div className="absolute basis-1/2 overflow-hidden z-0"> */}
                    {/* <StaticImage src="../../images/logos/rose.png" className="w-96 h-96 basis-1/2 justify-self-end overflow-hidden object-cover object-center opacity-50" /> */}
                    {/* <StaticImage src="../../images/logos/rose.png" className="overflow-hidden object-cover object-center opacity-50 -right-96 lg:-right-52 -top-1/2 absolute z-0" /> */}
                    {/* <StaticImage src="../../images/logos/rose.png" className="object-cover object-center opacity-50" /> */}
                    {/* </div> */}
                </div>
                {/* <div className="bg-contain w-1/2 h-full" style={{ backgroundImage: `url(${image})` }}> */}
                {/* </div> */}
            </div>

        </section >
    )
}

export default Cta
