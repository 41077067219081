import React from 'react'
import { graphql, Link } from 'gatsby'

const SplitImage = (props) => {
    return (
        <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gold-400">{props.heading}</h1>
                    <p className="mb-8 leading-relaxed">{props.content}</p>
                    <div className="flex justify-center">
                        <Link to={props.link}>
                            <button className="btn btn-secondary">{props.button}</button>
                        </Link>
                    </div>
                </div>
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <img className="object-cover object-center rounded shadow-md" alt="hero" src="https://dummyimage.com/720x600" />
                    {/* <img className="object-cover object-center rounded" alt="hero" src={data.strapiAboutUs.photo.localFile.url} /> */}
                </div>
            </div>
        </section>
    )
}

// export const data = graphql`
// query {
//     strapiAboutUs {
//       photo {
//         localFile {
//           url
//         }
//       }
//     }
//   }
//   `

export default SplitImage
