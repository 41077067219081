import React from 'react'
import { graphql, Link } from 'gatsby'
import Button from '../elements/Button'

const SplitImage2 = ({ content, heading, subheading, button, link, buttonpath, linkpath, imageleft, pic }) => {
    return (
        <section className="text-gray-600 body-font bg-white pb-8 lg:pb-0">
            <div className="container mx-auto flex px-5 pt-8 lg:flex-row flex-col items-center">
                <div className={`lg:flex-grow lg:w-1/2  flex flex-col lg:items-start lg:text-left ${(imageleft == 'true') ? 'lg:pl-24' : 'lg:pr-24 lg:mb-0'} `}>
                    {/* <div className="w-16 border-b-4 border-gray-200 mb-4"></div> */}
                    <small className='text-gray-400'>{subheading}</small>
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gold-400">{heading}</h1>
                    <p className="mb-8 leading-relaxed">{content}</p>
                    <div className="flex">
                        {/* <Link to={props.link}>
                            <button className="btn btn-secondary">{props.button}</button>
                        </Link> */}
                        <div className={` ${(button == 'hidden' ? 'hidden' : 'flex')}`}>
                            <Button path={buttonpath} style="primary">{button}</Button>
                        </div>
                        <div className={` ${(link == 'hidden' ? 'hidden' : 'flex')}`}>
                            <Button path={linkpath} style="link">{link}</Button>
                        </div>
                    </div>
                </div>
                <div className={`self-end relative hidden lg:flex lg:max-w-lg lg:w-full w-5/6 ${(imageleft == 'true') ? 'order-first mb-16 md:mb-0' : ''} `}>
                    <img className="object-cover object-center rounded-t-2xl shadow-md h-96 grayscale" alt="hero" src={`https://picsum.photos/id/${pic}/1000/600`} />
                    <div className="absolute inset-0 bg-blue-500/75 rounded-t-2xl"></div>
                </div>
            </div>
        </section>
    )
}

SplitImage2.defaultProps = {
    heading: "Default Heading",
    subheading: "",
    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil quis libero quidem animi quasi officia est debitis alias! Totam quisquam, ullam, quod officiis pariatur nulla eum commodi dolores eveniet nesciunt aperiam eos voluptatum nemo labore sunt ab sed nihil doloribus minus unde odit incidunt odio. Maiores inventore minima quod sequi!",
    imageleft: "false",
    pic: "484",
    button: "hidden",
    buttonpath: "#",
    link: "hidden",
    linkpath: "",
}

// export const data = graphql`
// query {
//     strapiAboutUs {
//       photo {
//         localFile {
//           url
//         }
//       }
//     }
//   }
//   `

export default SplitImage2
